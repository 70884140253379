import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import TicketItems from './TicketItems';

export default class App extends Component {

  constructor(props){
    super(props);
    this.initForm = this.initForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.baseUrl = process.env.REACT_APP_API_URL;
    this.stripeKey = process.env.REACT_APP_STRIPE_KEY;
    const urlParams = new URLSearchParams(window.location.search);
    this.vehicleNoParam = urlParams.get('vehicle_no');
    this.initialState =  {
      fields: {
        searchInput: this.vehicleNoParam ? this.vehicleNoParam : ''
      },
      errors: {},
      selection: 'license',
      ticketData: []
    }
    this.resetState = {
      booked: true,
      errors: {},
      fields: {searchInput: '', email: '', cardholder_name: ''},
      loading: false,
      ticketData: [],
      payableAmount: 0
    }
    this.state = this.initialState;
  }

  componentDidMount(){
    const defer = () =>  {
      if (window.Stripe) {
          this.initForm(this);
      } else {
          setTimeout(function() { defer ()  }, 50);
      }
    }
    this.refreshAfterSleep();
    defer();
  }

  refreshAfterSleep(){
		var SAMPLE_RATE = 5000; 
		var lastSample = Date.now();
	  
		function refresh() {
		  if (Date.now() - lastSample >= SAMPLE_RATE * 2) {
			window.location.reload();
		  }
		  lastSample = Date.now();
		  setTimeout(refresh, SAMPLE_RATE);
		}
		refresh();
	}

  manageBodyBackground(isShow){
    if(isShow){
      document.querySelector('#root').classList.add('dark');
    }else{
      document.querySelector('#root').classList.remove('dark');
    }
  }

  onlyEmail(name){
    let fields = this.state.fields;
    if(!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(fields[name])){
      return false;
    }
    return true;
  }

  manageSelectedTickets(id){
    let ticketData = JSON.parse(JSON.stringify(this.state.ticketData));
    for(let i=0; i<ticketData.length; i++){
      if(ticketData[i].id === id){
        ticketData[i].selected = !ticketData[i].selected; 
        break;
      }
    }
    let payableAmount = ticketData.filter(item => item.selected).reduce((a, b) =>  a+ b['total_amount'], 0);
    let fee = payableAmount ? (payableAmount * 0.029) + 30 : 0;
    let grandTotal = payableAmount ? payableAmount + fee : 0;
    this.setState({
      ticketData,
      payableAmount,
      fee,
      grandTotal
    });
  }

  getTickets(){
    if(!this.state.selection) return;
    if(!this.state.fields['searchInput']) return;
    this.manageBodyBackground(true);
    this.setState({loading: true});
    const get_ticket_by_license = '/enforcement/ticket/vehicle_number?vehicle_no=';
    const get_ticket_by_id = '/enforcement/ticket?ticket_id=';
    const url = this.state.selection === 'id' ? get_ticket_by_id : get_ticket_by_license;
    fetch(`${this.baseUrl}${url}${this.state.fields['searchInput']}`)
        .then(response => response.json())
        .then(resp => {
          if(resp.data && resp.status === "SUCCESS"){
            this.manageBodyBackground(false);
            let respData = Array.isArray(resp.data) ? resp.data : Object.keys(resp.data).length === 0 ? [] : [resp.data];
            respData = respData.filter(item => item.status != 'paid');
            respData.forEach((item, index) => {
              respData[index].selected = true;
            })
            let payableAmount = respData.filter(item => item.selected).reduce((a, b) =>  a+ b['total_amount'], 0);
            let fee = payableAmount ? (payableAmount * 0.029) + 30 : 0;
            let grandTotal = payableAmount ? payableAmount + fee : 0;
            this.setState({
              loading: false,
              ticketData: respData,
              payableAmount,
              fee,
              grandTotal
            }, () => { if(!this.state.ticketData.length) toast.error('No tickets found')});
          }else{
            this.manageBodyBackground(false);
            this.setState({loading: false});
            let errMsg = resp.error && resp.error.reason ? resp.error.reason : 'Something went wrong'; 
            toast.error(errMsg);
          }
        })
        .catch((error) => {
          this.manageBodyBackground(false);
          this.setState({loading: false});
          toast.error('Something went wrong');
        });
  }

  handleChange(e) {
    let fields = {...this.state.fields};
    fields[e.target.name] = e.target.value;
    this.setState({
        fields
      });
  }

  nonSpecialCharacter(name){
      let fields = this.state.fields;
      return !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(fields[name]);
  }

  initForm(_this){
    if(this.vehicleNoParam) this.getTickets();
    var stripe = window.Stripe(this.stripeKey);
    var elements = stripe.elements();

      var style = {
        base: {
          iconColor: '#666EE8',
          color: '#000',
          lineHeight: '40px',
          fontSize: '16px',
      
          '::placeholder': {
            color: '#000',
            fontWeight: 300,
            fontSize: '13px'
          },
        },
      };
      
      var card = elements.create('card', {style: style, hidePostalCode: false});

      card.mount('#card-element');

      card.addEventListener('change', function(event) {
        var displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });

    function setOutcome(result) {
      const submitBtn = document.getElementById("pay-submit");
      if (result.source && result.source.id) {
        _this.handleSubmit(result.source.id, card);
      } else if (result.error) {
        _this.manageBodyBackground(false);
        _this.setState({loading : false});
        toast.error(result.error.message ? result.error.message : 'Something went wrong', { autoClose: 10000 });
        submitBtn.disabled = false;
      }
    }

    const validateForm = () => {
      let fields = this.state.fields;
      let formIsValid = true;
      this.errors = {};
      const cardWrapper = document.querySelector('#card-element');
      const cardWrapperError = document.querySelector('#card-errors');
      /* if (!this.vehicleNoParam && (!fields["searchInput"] || (fields["searchInput"] && fields["searchInput"].trim() === ''))) {
        formIsValid = false;
        this.errors["searchInput"] = "enter the field";
      } */
      let selectedTickets = this.state.ticketData.filter(x => x.selected).length;
      if (!this.state.ticketData.length || !selectedTickets) {
        formIsValid = false;
        toast.error('Select a ticket');
      }
      if(cardWrapper.classList.contains('StripeElement--empty')){
        formIsValid = false;
        cardWrapperError.innerHTML = "enter your card number";
      }
      if(cardWrapper.classList.contains('StripeElement--invalid')){
        formIsValid = false;
        if(!cardWrapperError.innerHTML.trim()) cardWrapperError.innerHTML = "enter valid details";
      }
      if (!fields["cardholder_name"] || (fields["cardholder_name"] && fields["cardholder_name"].trim() === '')) {
        formIsValid = false;
        this.errors["cardholder_name"] = "enter the cardholder name";
      }
      if (!fields["email"] || (fields["email"] && fields["email"].trim() === '')) {
        formIsValid = false;
        this.errors["email"] = "enter your email";
      }
      if (fields["email"] && fields["email"].trim() != '') {
        const isValid = this.onlyEmail("email");
        if (!isValid) {
          formIsValid = false;
          this.errors["email"] = "enter a valid email address";
        }
      }
      
      this.setState({
        errors: this.errors
      });
      return formIsValid;
    }

    document.querySelector('#pay-submit').addEventListener('click', function(e) {
      e.preventDefault();
      document.getElementById("pay-submit").disabled =  true;
      if(_this.state.loading) return;
      const submitBtn = document.getElementById("pay-submit");
      const isValid = validateForm();
      if(!isValid) {
        submitBtn.disabled = false;
        return;
      }
      /* if(!_this.state.totalFee){
        toast.error('Please enter valid spot no. & duration');
        submitBtn.disabled = false;
        return;
      } */
      _this.manageBodyBackground(true);
      _this.setState({loading : true});
      let { fields } = _this.state; 
      let options = {
        owner: {
          name: fields.cardholder_name,
          email: fields.email
        }
      }
      stripe.createSource(card, options).then(setOutcome, _this);
    });
  }

  handleSubmit(id, card){
    let { fields } = this.state; 
    const submitBtn = document.getElementById("pay-submit");
    const request = {
      source_id: id,
      ticket_ids: this.state.ticketData.filter(x => x.selected).map(x => x.id),
      cardholder_name: fields["cardholder_name"] ? fields["cardholder_name"] : ''
    }
    fetch(`${this.baseUrl}/enforcement/ticket/payment`, {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify(request)
     })
      .then(response => response.json())
      .then(resp => {
        if(resp.data && resp.status === "SUCCESS"){
          window.scrollTo(0,0);
          this.setState({...this.resetState, bookingID: resp.data.booking_id}, () => {
            card.clear();
            submitBtn.disabled = false;
          });
        }else{
          let errMsg = resp.error && resp.error.reason ? resp.error.reason : 'Something went wrong'; 
          this.manageBodyBackground(false);
          this.setState({loading : false});
          toast.error(errMsg, { autoClose: errMsg.length > 20 ? 10000 : 2000 });
          submitBtn.disabled = false;
        }
      })
      .catch((error) => {
        this.manageBodyBackground(false);
        this.setState({loading : false});
        toast.error('Something went wrong');
        submitBtn.disabled = false;
      });  
  }

  handleFocus(name){
    document.querySelector(`input[name="${name}"]`).focus();
    return false;
  }

  handleClose(){
    this.setState({booked: false, bookedID: null }, () => {
      this.manageBodyBackground(false);
    })
  }

  handleSelection(e){
    this.setState({
      selection: e.target.value
    });
  }


  render() {
    return (
      <div style={{position: 'relative'}}>
        {this.state.loading && <div style={{position: 'absolute', width: '100%', height: '100%', zIndex: 10}}></div>}
        {this.state.loading && <div className="loader">Loading...</div>}
        <div style={{display: this.state.booked ? 'block' : 'none'}} className="success-message">
          <span onClick={this.handleClose.bind(this)} id="sm-close">&times;</span>
          <svg viewBox="0 0 76 76" className="success-message__icon icon-checkmark">
            <circle cx="38" cy="38" r="36"></circle>
            <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M17.7,40.9l10.9,10.9l28.7-28.7" style={{strokeDashoffset: 0, strokeDasharray: 56.0029}}></path>
          </svg>
          <h3 className="success-message__title">Payment Successful</h3>
          <div className="success-message__content">
              {/* <p>Booking Id: {this.state}</p> */}
          </div>
        </div>
        
        <form id="mainForm" style={{pointerEvents: this.state.booked || this.state.seeMore ? 'none' : 'auto'}} action="#" method="POST">
          <input type="hidden" name="token" />
         {/*  <div className="logo">
            <img style={{height: 50}} src="https://portal.parkquility.com/public/pq_logo.png" />
          </div> */}
          {/* <div style={{textAlign: 'center', marginTop: '30px'}}>
            <div class="select">
              <select onChange={this.handleSelection.bind(this)} value={this.state.selection}>
                <option value="id">Pay by Ticket #</option>
                <option value="license">Pay by License Plate #</option>
              </select>
            </div>  
          </div> */}
          <div style={{marginTop: '35px'}} className="ticket-wrapper">
            {!this.vehicleNoParam && <div className="upper search-field">
              <span onClick={() => this.handleFocus('searchInput')}>{this.state.selection === 'id' ? 'Ticket ' : 'License Plate ' }#</span>
              <input
                id="searchInput"
                className="pay-fields" 
                value={this.state.fields['searchInput']}
                style={{textTransform: 'uppercase'}}
                name="searchInput"
                onChange={this.handleChange}
                maxLength={40}
                onKeyDown={(e) => { if(e.key == 'Enter') this.getTickets()}}
              />
              <button onClick={this.getTickets.bind(this)} type="button">Search</button>
              <div className="error-msg">{this.state.errors['searchInput']}</div>
            </div>}
            {this.state.ticketData.length > 0 && 
              this.state.ticketData.map((item, index) => <TicketItems key={'t' + index} manageSelectedTickets={this.manageSelectedTickets.bind(this)} length={this.state.ticketData.length} data={item} />)
            }
            
            <div className="amount-block" style={{visibility: this.state.ticketData.length ? 'visible' : 'hidden', opacity: this.state.ticketData.length ? 1 : 0}}>
              <h3 style={{paddingTop: 20, fontSize: '17px'}}>Payment Amount : {this.state.payableAmount ? '$' + (this.state.payableAmount/100).toFixed(2) : '$0.00'}</h3>
              {/* <h3 style={{paddingTop: 20}}><span>Ticket Amount </span><span>{this.state.payableAmount ? '$' + (this.state.payableAmount/100).toFixed(2) : '$0.00'}</span></h3>
              <h3><span>Convenience Fee </span><span> {this.state.fee ? '$' + (this.state.fee/100).toFixed(2) : '$0.00'}</span></h3>
              <h3><span style={{fontSize: '17px'}}>Total Amount </span><span style={{fontSize: '17px'}}>{this.state.grandTotal ? '$' + (this.state.grandTotal/100).toFixed(2) : '$0.00'}</span></h3> */}
              <h6>Card Information</h6>
              <div className="card-info">
                <div id="card-element">
                </div>
              </div>
              <div className="error-msg" id="card-errors" role="alert"></div>
              <div class="group">
                <label>
                  <input onChange={this.handleChange} value={this.state.fields["cardholder_name"]} style={{borderRight: '1px solid #F0F5FA'}} name="cardholder_name" class="field" placeholder="Cardholder name" />
                </label>
                <div style={{paddingLeft: '20px'}} className="error-msg">{this.state.errors['cardholder_name']}</div>
                <label>
                  <input onChange={this.handleChange} value={this.state.fields["email"]} name="email" class="field" placeholder="Email" />
                </label>
              </div>
              <div style={{paddingLeft: '20px'}} className="error-msg">{this.state.errors['email']}</div>
              <button  id="pay-submit" type="button">Make Payment</button>
              <div style={{clear: 'both'}} />
            </div>
          </div>
          <ToastContainer autoClose={2000} closeButton={true} style={{fontSize: 14}}/>
        </form>
      </div>      
    )
  }
}
