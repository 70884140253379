import React, { Component } from 'react';
import gpsIcon from "./img/gps.png";

export default class TicketItems extends Component {


    handleImage(data){
        let elem;
        if(data.multiple_image && data.multiple_image_urls && data.multiple_image_urls.length){
            elem = data.multiple_image_urls.map(item => <div><img style={{width: '200px', marginBottom: '7.5px'}} src={item} /></div>);
        }else{
            elem = <img style={{width: '200px'}} src={data.vehicle_image_url} />;   
        }
        return elem;
    } 

    render() {
        const data = this.props.data;
        if(!data) return null;
   


    return(
        <div key={data.id} className="ticket-item">
                {this.props.length > 1 && <label class="check-wrapper"> 
                    <input onClick={() => this.props.manageSelectedTickets(data.id)} type="checkbox" checked={data.selected} />
                    <span class="checkmark"></span>
                </label>}
                <div style={{textAlign: 'left', paddingBottom: '10px', paddingRight: '25px'}} className="location">
                    <img style={{height: 28}} src={gpsIcon} /> 
                    <span style={{lineHeight: '26px'}}>{data.address}</span>
                </div>
                <ul className="pay-timings">
                    <li>
                        <span className="list-initial">Ticket #:</span>
                        <span>{data.id}</span>
                    </li>
                    <li>
                        <span className="list-initial">Issue Time</span>
                        <span>{data.ticket_issued_time}</span>
                    </li>
                    <li>
                        <span className="list-initial">License Plate No</span>
                        <span>{data.vehicle_no}</span>
                    </li>
                    <li>
                        <span className="list-initial">Vehicle Make</span>
                        <span>{data.vehicle_make}</span>
                    </li>
                    <li>
                        <span className="list-initial">Vehicle Type</span>
                        <span>{data.vehicle_type}</span>
                    </li>
                    <li>
                        <span className="list-initial">Vehicle Color</span>
                        <span>{data.vehicle_color}</span>
                    </li>
                    <li>
                        <span style={{fontSize: '16px', fontWeight: 'bold'}} className="list-initial">Fine</span>
                        <span style={{fontSize: '16px', fontWeight: 'bold'}}>{data.fine_amount ? '$' + (data.fine_amount/100).toFixed(2) : '$0.00'}</span>
                    </li>
                </ul>
                <div style={{margin: '15px 0'}} className='text-center'>
                    <h5>License Plate Image</h5>
                    <div>{this.handleImage(data)}</div>
                </div>
                <div style={{marginTop: '15px'}}>   
                    <ul className="pay-timings">
                        <li>
                            <span style={{width: '172px'}}>Late Payment Fee (Extra)</span>
                            <span></span>
                        </li>
                    </ul>    
                </div>
                <div style={{margin: '15px 0'}}>
                    <div class='enf-row'>
                        <div class='enf-column'>
                            Days
                        </div>
                        {data.late_payment && Object.keys(data.late_payment).map((key, keyIndex) => {
                            let keyName = key;
                            let objLength = Object.keys(data.late_payment).length;
                            if(objLength === keyIndex +  1){
                               keyName = keyName.replace('-', '+');
                            }else{
                               keyName = keyName;
                            }
                            return(
                                <div class='enf-column'>
                                    {keyName}
                                </div>
                            )
                        })}
                        
                    </div>
                    <div class='enf-row 2'>
                        <div class='enf-column'>
                            Amount
                        </div>
                        {data.late_payment && Object.keys(data.late_payment).map((key) => {
                            return(
                                <div class='enf-column'>
                                    {data.late_payment[key] ? '$' + (data.late_payment[key]/100).toFixed(2) : '$0.00'}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div>   
                    <ul className="pay-timings">
                        <li>
                            <span className="list-initial">Delay</span>
                            <span>{data.delay}</span>
                        </li>
                        <li>
                            <span className="list-initial">Late Payment Fee</span>
                            <span>{data.penalty_amount ? '$' + (data.penalty_amount/100).toFixed(2) : '$0.00'}</span>
                        </li>
                    </ul>
                </div>
            </div>  
    )
}

}